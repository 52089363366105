<div class="modal-header" style="padding: 0 !important;margin-top: -40px;">
    <div class="row ">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="container-fluid">
                <div class="row cre_header">
                    <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                        <h6 *ngIf="actionPlantype =='A'">Create a Task <img src="assets/images/task_icon.png" alt="">
                        </h6>
                        <h6 *ngIf="actionPlantype =='E'">Update a Task <img src="assets/images/task_icon.png" alt="">
                        </h6>
                        <h6 *ngIf="actionPlantype =='V'"> Task Details <img src="assets/images/task_icon.png" alt="">
                        </h6>

                    </div>
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;" data-dismiss="modal" (click)="closeModal()">
                        <img src="assets/images/cls_icon.png" alt="close">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader-animation *ngIf="isLoading"></app-loader-animation>
<div class="modal-body" style="padding: 0rem 1rem 1.5rem;background-color: #e8ebee;border-radius: 3px;">
    <div class="container">
        <form [formGroup]="taskform">
            <div class="row" *ngIf="actionpalntitle !=''">
                <div class="col-8 col-sm-11 col-md-10 col-lg-12 col-xl-12">
                    <div class="form-group">
                        <p style="font-weight: bold;margin-top: 1%;">{{actionpalntitle}}</p>

                    </div>

                </div>
            </div>
            <br>


            <div class="row">
                <div class="col-8 col-sm-11 col-md-10 col-lg-12 col-xl-12">

                    <div class="form-group">
                        <label for="exampleInputEmail1">Task Name</label>
                        <input type="text" [attr.disabled]="viewmode ? '' : null" class="form-control" id="Taskname" placeholder="Enter Taskname" formControlName="taskname" [(ngModel)]="taskname">

                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-8 col-sm-11 col-md-10 col-lg-12 col-xl-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Task Details</label>
                        <textarea class="form-control" [attr.disabled]="viewmode ? '' : null" id="Task Details" rows="3" formControlName="taskdetails" placeholder="Enter Task Details" [(ngModel)]="taskdetails"></textarea>

                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-4 ">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Target Date</label>
                        <input type="date" [attr.disabled]="viewmode ? '' : null" class="form-control" id="targetDate" name="targetDate" formControlName="targetDate" [min]="minDate" [(ngModel)]="targetDate" style="font-family:'Gotham\ Black';font-size: 0.72rem;">
                        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                    </div>

                </div>
                <div class="col-6 " style="margin-left: 3rem;">
                    <div class="form-group">
                        <label class="exampleInputEmail1">Select Priority</label>
                        <div>
                            <button class="btn4" [attr.disabled]="viewmode ? '' : null" [ngClass]="{'highp' : ta_Priority === '1'}" (click)="ta_Priority = '1'">High</button>
                            <button class="btn4" [attr.disabled]="viewmode ? '' : null" [ngClass]="{'mediump' : ta_Priority === '2'}" (click)="ta_Priority = '2'">Medium</button>
                            <button class="btn4" [attr.disabled]="viewmode ? '' : null" [ngClass]="{'lowp' : ta_Priority === '3'}" (click)="ta_Priority = '3'">Low</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="actionPlantype =='E' || actionPlantype =='V'">
                <div class="col-4 col-sm-4 col-md-7 col-lg-5 col-xl-5">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Created By</label>
                        <p style="font-weight: bold;">{{createdby}} @ <span style="color: #0b95f4;">{{createDate | date:'MM/dd/yyyy':'GMT'}}</span></p>

                    </div>

                </div>
                <div class="col-3 col-sm-3 col-md-6 col-lg-4 col-xl-4 align-self-center pl-0">
                    <p *ngIf="assignee != undefined && assignee.length !=0"><img *ngIf="createdid ==userInfo.id || assignee?.sc_id ==userInfo.id " src="assets/images/Checkmark-Icon.png" class="editimg" (click)="completedtask(pop,'C','Are you sure you want complete this Task?')"></p>
                    <p *ngIf="assignee == undefined || assignee.length ==0"><img *ngIf="createdid ==userInfo.id" src="assets/images/Checkmark-Icon.png" class="editimg" (click)="completedtask(pop,'C','Are you sure you want complete this Task?')"></p>

                </div>
            </div>

            <div class="row">
                <div class="col-4 col-sm-6 col-md-6 col-lg-9 col-xl-10">
                    <label for="exampleInputEmail1">Assigned To</label>
                    <div class="form-group">
                        <div class="row assigner" style="padding: 1px 0;align-items: center;">


                            <div class="col-md-6" style="margin-left: -1.5%;" *ngIf="assignee != undefined && assignee.length !=0">
                                <div class="col-md-11">
                                    <div class="" [ngClass]="( actionPlantype=='A' || actionPlantype=='E')?'c_dropdown':'inactive_events f_dropdown'" (click)="openActionPlanFollowers(template,'A')">
                                        <ul>
                                            <li>
                                                <div class="">
                                                    <img class="pic" src="https://devadmapi.netprophet.auto/api/scprofileimage/{{assignee?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                                </div>
                                                <div class="des">
                                                    <div class="name">{{assignee?.sc_firstname}} {{assignee?.sc_lastname}}
                                                    </div>
                                                    <div class="role">{{assignee?.sc_title}}</div>
                                                </div>
                                            </li>
                                            <li>Europe</li>
                                            <li>America</li>

                                            <span></span>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" style="margin-left: -1.5%;" *ngIf="assignee == undefined || assignee.length ==0">
                                <div class="col-md-11">
                                    <div class="" [ngClass]="(actionPlantype=='A' || actionPlantype=='E')?'c_dropdown':'inactive_events f_dropdown'" (click)="openActionPlanFollowers(template,'A')">

                                        <ul>
                                            <li>
                                                <div class="pic"></div>
                                                <div class="des">
                                                    <div class="name">Unassigned</div>
                                                    <div class="role">Click Here!</div>
                                                </div>
                                            </li>
                                            <span></span>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <!-- <div class="row" >
			<div class="col-9 col-sm-11 col-md-10 col-lg-12 col-xl-12">
                   
			<label for="exampleInputEmail1">Followed By</label>
                    <div class="form-group">
 <div class="row assigner mr-6" style="padding: 3px 0;align-items: center;">
         

            <div class="col-md-6" *ngIf="followers.length !=0"
                [ngClass]="followers.length > 4 ? 'followers_height' : ''">
                <div class="col-md-12 " *ngFor="let item of followers">
                    <div >
                    <div class="f_dropdown" >
                        <ul>
                            <li>
                                <div class="">
                                    <img class="pic"
                                        src="http://npapiadm.azaz.com/api/scprofileimage/{{item?.sc_profile_image}}"
                                        onError="this.src='../../../assets/images/default-img.jpg'">
                                </div>
                                <div class="des">
                                    <div class="name">{{item?.sc_firstname}} {{item?.sc_lastname}}</div>
                                    <div class="role">{{item?.sc_title}}</div>
                                </div>
                            </li>
                            <li>Europe</li>
                            <li>America</li>

                            <span></span>
                        </ul>
                    </div>
                </div>
                </div>
            </div>

            
            <div class="col-md-1 mt-0" *ngIf="followers.length ==0 ||followers.length !=0 "
                style="margin-left: 1.2rem;">
               
                <div class="col-md-1 pl-lg-0 cur"  *ngFor="let item of followers"
                    [ngClass]="(actionPlantype =='A' || actionPlantype =='E' )?'my-class1':'inactive_events'"
                    (click)="openActionPlanFollowers(template,'F')"><img src="assets/images/cir_sml.png"  *ngIf="actionPlantype !='V'">
                </div>
            </div>
           
            <div class="col-md-1 p-0">
               
            </div>
        </div>
			
</div>
</div>
        </div> -->

            <div class="row">
                <div class="col-6">
                    <label for="exampleInputEmail1">Followed By</label>

                    <div class="form-group" style="display: flex;" *ngIf="followers.length ==0 ||followers.length !=0 ">


                        <div class="tooltip" *ngFor="let item of followers"><img style="height: 40px; padding: 6%; border-radius: 50px;" src="https://devadmapi.netprophet.auto/api/scprofileimage/{{item?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'" alt="Image 1" />
                            <span class="tooltiptext">{{item?.sc_firstname}} {{item?.sc_lastname}}</span></div>


                        <div style="margin-top: 0.6rem; padding: 1%;" [ngClass]="(actionPlantype =='A' || actionPlantype =='E' )?'my-class1':'inactive_events'" (click)="openActionPlanFollowers(template,'F')"><img style="cursor: pointer;" src="assets/images/cir_sml.png" *ngIf="actionPlantype !='V'">
                        </div>
                    </div>



                </div>

            </div>





            <div style="text-align: center;" *ngIf="actionPlantype !='V'" (click)="(actionPlantype != 'E')?onSubmit():onEditSubmit()">
                <input type="submit" class="btndark" value="Save" style="border:none;width:30%">
            </div>
        </form>
    </div>
</div>


<!-- <p>Move the mouse over the text below:</p>
    
    <div class="tooltip"><img class="size_of_img" src="https://babeltechreviews.com/wp-content/uploads/2018/07/rendition1.img_.jpg" alt="Image 1" /><span class="tooltiptext">grewon.pdf</span></div>
    
    <p>Note that the position of the tooltip text isn't very good. Check More Position <a href="https://www.w3schools.com/css/css_tooltip.asp">GO</a></p> -->

<ng-template #template let-modal>
    <app-acton-plan-followers [followersassigned]="followers" [approvedBy]="approvedBy" [apCreatedId]="actionPlanCreatedId" [assignee]="assignee" [type]="type" (onClose)="closeModalf($event)" (onAdd)="changeImg($event)"></app-acton-plan-followers>
</ng-template>
<ng-template #template1 let-modal>
    <app-action-messages (onClosemsg)="onClosemsg()"></app-action-messages>
</ng-template>
<ng-template class="popup-dialog" #pop let-modal style="top:20rem">
    <app-commo-alert [alertType]="actionPlantypeAlert" (onCloseAlert)="onCloseAlert($event,pop)" [alertContent]="alertContent"></app-commo-alert>
</ng-template>