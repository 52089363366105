import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from '../common';
import { NPApi } from '../Providers/NP-Api';
declare var require: any;
@Component({
  selector: 'app-dashboard-side-nav',
  templateUrl: './dashboard-side-nav.component.html',
  styleUrls: ['./dashboard-side-nav.component.scss']
})

export class DashboardSideNavComponent implements OnInit {
  // storeinfo: any = [];
  // dashInfo: any = [];
  // cash: any;
  // gross: any;
  // expence: any;
  // comp: any;
  // profit: any;
  // stafig: any;
  // revenue: any;
  // staffing: any;
  // Dinfo: any;
  monthvalues: any;
  total; any;
  dash_sec_grads: any;
  //selectedItem: any = 0;
  //MonthsDdl: any;
  monthyear: any;
  userInfo: any;

  @Output() changeSidemenu = new EventEmitter();
  @Output() notificationClick = new EventEmitter();
  constructor(public comm: Common, public routerr: Router, private api: NPApi,
   private ngbmodal: NgbModal, private ngbmodalActive: NgbActiveModal,) {
    this.comm.head = true;
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    //userInfo.id
    // if(this.comm.pageid==2){
    let id = localStorage.getItem('store_id');
    let monthyear = localStorage.getItem('monthYear');
    console.log(id, monthyear);
    this.getdashsection_grades(id, monthyear);
    // }
    // this.api.getStoresInfo(this.userInfo.id).subscribe((data: any) => {
    //   console.log(data);
    //   //let data1=data;
    //   this.storeinfo = (data.status == 200) ? JSON.parse(JSON.stringify(data.response)) : []
    //   this.comm.selectedItem = 1;
    //   //this.GetDashBoard(this.storeinfo[0].Store_Id, 0);
    //   //  this.storeinfo=data;
    //   console.log(this.storeinfo, 'vv');

    // });
    // this.api.getnotifications().subscribe((notify: any) => {

    //   console.log('notification', notify);

    //   this.getnotificationsList();



    // });


    this.getnotificationsList();
  }

  ngOnInit(): void {
  }

  // parseXml(xmlStr) {
  //   var result;
  //   var parser = require('xml2js');
  //   parser.Parser().parseString(xmlStr, (e, r) => { result = r });
  //   return result;
  // }
  //   selectstore(id: any) {
  //     //getted from event
  //     console.log(id);
  //      alert('store id'+id)
  //     localStorage.setItem('store_id', id);
  //     //getted from binding
  //     // console.log(this.selected)
  // //    this.GetDashBoard(id, 1);
  //   }

  // selectmonthyear(id: any) {

  //   let storeid = localStorage.getItem('store_id');
  //   this.monthyear = id;
  //   this.GetDashBoard(storeid, this.monthyear);
  // }

  // getsection(secid) {
  //   if(this.comm.pageid==6 || this.comm.pageid == 31){
  //     this.comm.selectedlink = 10;
  //   }
  //   else if( this.comm.pageid==21){
  //     this.comm.selectedlink = 10;
  //     this.routerr.navigate(['1']);
  //   }
  //   localStorage.setItem('tabid',secid);
  //   this.changeSidemenu.emit({ obj: secid });
    
  // }


  getdashsection_grades(id: any, monthYear: any) {
    this.api.Dash_sectionGrds(id, monthYear).subscribe(
      (data: any) => {
        this.dash_sec_grads = data.response;
        console.log(this.dash_sec_grads);

        data.response.filter(item => {
          if (item.section_name == 'cash') {
            this.comm.gra_cash = item.section_grade;
          }
          if (item.section_name == 'expense') {
            this.comm.gra_expense = item.section_grade;
          }
          if (item.section_name == 'revenue') {
            this.comm.gra_revenue = item.section_grade;
          }
          if (item.section_name == 'profit') {
            this.comm.gra_profit = item.section_grade;
          }
          if (item.section_name == 'compensation') {
            this.comm.gra_comp = item.section_grade;
          }
        })
        //  gra_cash:any='D';
        //  gra_revenue:any='D';
        //  gra_expense:any='D';
        //  gra_comp:any='D';
        //  gra_profit:any='D';

      });
  }


  // GetDashBoard(id, flag) {

  //   if (flag != 0) {
  //     //this.spinner.show();
  //     setTimeout(() => {
  //       /** spinner ends after 5 seconds */
  //       //this.spinner.hide();
  //     }, 1000);
  //   }
  //   let exp = (flag == 0 || flag == 1) ? "" : this.monthyear.trim()
  //   this.api.GetDash(id, exp).subscribe((data: any) => {
  //     let dashInfo = (data.status == 200) ? data.response : [];
  //     //console.log(dashInfo);
  //     this.dashInfo = dashInfo.Dashboard;
  //     if (exp == "")
  //       this.MonthsDdl = this.dashInfo.ddlmonths[0].Month

  //     console.log(this.dashInfo);

  //     console.log(this.MonthsDdl);
  //     // this.Dinfo=element;
  //     this.comm.selectedItem = 1;
  //     this.Dinfo = this.dashInfo.Dash[0].Sections[0];
  //     this.total = this.dashInfo.Dash[0].totalval[0];

  //     // this.monthvalues= this.Dinfo.section[0].Prev_Months[0].split(',');


  //     console.log(this.monthvalues);
  //     this.dashInfo.Dash[0].Sections.forEach(element => {
  //       console.log(element);

  //       if (element.section[0].Section[0] == 'cash') {
  //         this.cash = element;
  //       }
  //       else if (element.section[0].Section[0] == 'expense') {
  //         this.expence = element;

  //       }
  //       else if (element.section[0].Section[0] == 'profit') {
  //         this.profit = element;
  //       }
  //       else if (element.section[0].Section[0] == 'revenue') {
  //         this.revenue = element;
  //       }
  //       else if (element.section[0].Section[0] == 'gross') {
  //         this.revenue = element;
  //       }
  //       else if (element.section[0].Section[0] == 'compensation') {
  //         this.comp = element;
  //       }

  //       else if (element.section[0].Section[0] == 'staffing') {
  //         this.staffing = element;
  //       }
  //       //staffing  
  //       //this.spinner.hide();
  //     }, error => {
  //       // this.spinner.hide();
  //     });
  //     // this.comp=this.comp.section.length>0?
  //     // console.log(this.comp.section.length)
  //     //console.log(this.cash.section.length);
  //     // console.log(this.cash);

  //   });

  // }
  // splitvalue(str, posin) {
  //   let pmval = str.split(',');
  //   let mval = (pmval.length >= 3) ? pmval[posin].split('|')[1] : "00";
  //   return mval;
  // }
  // bindmonthdirc(str, posin) {
  //   let pmval = str.split(',');
  //   let mval = (pmval.length >= 3) ? pmval[posin].split('|')[2] : 'D';
  //   //this.binddirc(mval);
  //   let url = "";
  //   return url = (mval == 'U') ? 'assets/images/sml_grn_arw.png' : 'assets/images/sml_red_arw.png';
  // }
  // splitmontval(str, posin, pos2) {
  //   let pmval = str.split(',');
  //   let val = (pmval.length >= 3) ? pmval[posin].split('|')[0] : "00";
  //   let mon = "";
  //   if (pos2 == 1)
  //     mon = val.substr(2, 2);
  //   else
  //     mon = val.substr(4, 2);

  //   // console.log(mon);
  //   return mon;
  // }


  // toFixedSpecial(num:any) {
  //    var x= this.convert(num);
  //   // console.log()
  //   let n=x.includes('.')?x:x+'.'+'00';
  //    let parts =n.toString().split(".");
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // parts[1]=parts[1].substr(0,2);
  // parts=parts[0]+"."+parts[1];
  // console.log(parts);
  // return parts;
  //    //,{exponential:{lower:1e-100,upper:1e100}}))
  // }

  toFixedSpecial(number) {

    var n = number < 0 ? number.slice(1) : number;
    // if(n.length>=7){
    //   let num=this.test(n);
    //   return num;
    // }else

    var parts = n.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts[1] > 0)
      return parts.join('.');
    else
      return parts[0];

    //  var n = num < 0 ? num.slice() : "",
    // return n.toLocaleString();
    // var [lead,decimal,pow] = n.toString()
    //     .replace(/^-/,"")
    //     .replace(/^([0-9]+)(e.*)/,"$1.$2")
    //     .split(/e|\./);
    // return +pow < 0 
    //     ? "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    //     :  lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }

  bind_grade(grd) {
    let url = '../../assets/SvgLogo/';
    //url(../../assets/SvgLogo/NetProphet_Logo.svg);
    
    //
    if (grd == 'A')
      url = url + 'Leaf.svg';
    else if (grd == 'B')
      url = url + 'Water.svg'
    if (grd == 'C')
      url = url + 'Warning.svg';
    else if (grd == 'D')
      url = url + 'Flame.svg'

    return url;
  }

  binddirc(dirc) {
    let url = "";
    return url = (dirc == 'U') ? 'assets/images/sml_grn_arw.png' : 'assets/images/sml_red_arw.png';
  }


  test(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

          : Math.abs(Number(labelValue));
  }

  notifyslist : any = [];
  getnotificationsList(){
    let body = {
      userid: this.userInfo.id,
      type: '',
      Storeid: localStorage.getItem('store_id')
    };
    // this.isLoading = true;
    this.api.Getnotifications(body).subscribe((res: any) => {
      this.notifyslist=[];
      // this.isLoading = false;
      if (res.status == 200) {
        this.comm.notifyslist = res.response;
      }
    }, err => {
      // this.isLoading = false;
    });
  }

  parseXml(xmlStr) {
    const Obj = [];
    var result;
    var parser = require('xml2js');
    parser.Parser().parseString(xmlStr, (e, r) => { result = r });
    if (result){
      Obj.push(result);
    }
    return Obj;
  }
  // navigatetodetails(data,subdata){
  //   console.log(subdata);
  //   if(data.ntype == 'M' ){
  //     this.comm.title="Dash";
  //     this.routerr.navigate(['messenger', subdata.Messages.Message[0].MsGConvId[0]]);
  //   }else if(data.ntype == 'T'){
  //     this.routerr.navigate(['/8']);
  //   }else if(data.ntype == 'A'){
  //     this.routerr.navigate(['/2']);
  //   }
  // }
actionPlantype: any = "V";
actionPlanId=0;
taskid=0;
editActionPlanId=0;
  navigatetodetails(data,subdata,content,temp){
    console.log(subdata);
    if(data.ntype == 'M' ){
      this.comm.title="Dash";
      this.routerr.navigate(['messenger', subdata.Messages.Message[0].MsGConvId[0]]);
      this.notificationClick.emit(subdata);
    }else if(data.ntype == 'T'){

      this.routerr.navigate(['/8']).then(()=>{
        setTimeout(() => {
          this.taskid = subdata.Tasks.Task[0].TaskId[0];
      this.ngbmodalActive = this.ngbmodal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: true,
          });
        }, 1500);
          
      });
        
      
      
    }else if(data.ntype == 'A'){
      this.routerr.navigate(['/2']);
      setTimeout(() => {
        this.ngbmodalActive = this.ngbmodal.open(temp, {
      size: 'lg', backdrop: 'static', keyboard: true,
          });
        this.editActionPlanId =subdata.Actionplans.Actionplan[0].ApId[0];
        this.actionPlantype = "E";       
      }, 1500);
 
      

    }

  }

   closeModalf(data) {
    this.ngbmodalActive.dismiss();
  }

  getsection(secid) {
    if(this.comm.pageid==6 ){
      this.comm.selectedlink = 10;
    }
    else if( this.comm.pageid==21 ){
      this.comm.selectedlink = 10;
      this.routerr.navigate(['1']);
    }
    else if(this.comm.pageid == 4 || this.comm.pageid == 31){
      this.comm.selectedlink = 10;
      this.comm.selectedItem=secid;
      localStorage.setItem('tabid',this.comm.selectedItem);
      this.routerr.navigate(['10']);
    }
    localStorage.setItem('tabid',secid);
    this.changeSidemenu.emit({ obj: secid });
  }

}
